<template>
    <v-card hover>  
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">{{title}}</v-col>
            </v-row>        
        </v-card-title>                     
        <v-card-text>
            <v-row>
                <v-col class="ml-5">
                    <v-text-field label="주소" v-model="searchKeyword" prepend-icon="mdi-map-marker" required v-on:keyup.enter="searchAddr"></v-text-field>                  
                </v-col>
                <v-col cols="3" class="mt-2">
                    <v-btn color="indigo" dark v-on:click="searchAddr()">검색</v-btn>
                    <v-btn color="warning" dark class="ml-3" v-on:click="cancelCard()">취소</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="searched">
                <v-col>
                    <v-simple-table>
                    <thead>
                        <tr>
                        <th class="text-left">우편번호</th>
                        <th class="text-left">주소</th>
                        <th class="text-left">선택</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="addr in addrList" v-bind:key="addr.addr_no">
                        <td v-on:click="selectAddr(addr)"> {{addr.post_no}} </td>
                        <td v-on:click="selectAddr(addr)"> {{addr.addr1}} {{addr.bd_nm}} </td>
                        <td>
                            <v-btn class="ma-1" color="success" icon dark small v-on:click="selectAddr(addr)"><v-icon>mdi-check-circle</v-icon></v-btn>
                        </td>
                        </tr>
                    </tbody>
                    </v-simple-table>                
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    
</template>

<script>
import svcWvApi from '@/api/svc_wv'

export default {
    props: {
        title: String,
        modify: Boolean,
        group: {
            type: Object,
            default: function() {
                return {
                    id: -1,
                    name: '',
                    children: [],
                    members: []
                }
            }
        }
    },
    data: () => ({
        submitDisabled: true,   
        searchKeyword: '',
        pageNo: 1,
        addrList: [],
        searched: false,
    }),
    methods: {
        searchAddr() { 
            if(this.searchKeyword == '') { 
                alert("검색어를 입력해 주세요")
                return
            }

            let ctx = this
            let scb = function(data) { 
                ctx.addrList.splice(0, ctx.addrList.length)
                if(data){
                    data.forEach((item, idx) => { 
                        ctx.addrList.push(item)
                    })
                }
                ctx.searched = true
            }
            let fcb = function(err) { 
                alert('검색을 실패 했습니다. : '+ err)
            }

            svcWvApi.searchAddr(this.searchKeyword, this.pageNo, scb, fcb)
        },
        selectAddr(addr) { 
            this.$emit('submit', addr)
        },
        cancelCard() { 
            this.$emit('cancel')
        }
    }
}
</script>
